import React, { useState, useEffect } from "react"
import css from "@emotion/css"
import { injectStripe } from "react-stripe-elements"
import { StripeProvider, Elements } from "react-stripe-elements"

import { useFormState } from "src/components/Form"
import useCurrentUser from "src/hooks/useCurrentUser"
import { ButtonBlue } from "src/componentsV2/StyledButton"
import PaymentLoading from "src/components/Payment/paymentLoading"
import { useSubscriberEmail } from "src/hooks/useLocalStorage"
import {
  Input,
  PhoneInput,
  ErrorMessage,
  FormField,
} from "src/componentsV2/InputForm"
import getErrorMsgFromResponse from "src/utils/getErrorMsgFromResponse"
import config from "src/config"

import {
  FormStyled,
  Row,
  EmailInputWithRealTimeValidation,
  userFormName,
} from "./style"
import PaymentForm from "../Payment"
import paymentIcon from "../images/icons/payment.svg"

declare global {
  interface Window {
    analytics: any
  }
}

const PhoneInputRegister = Input.withComponent(PhoneInput)
PhoneInputRegister.validate = null

function validateInput(values: any): string | void {
  if (!values.first_name) {
    return "Please provide a valid first name."
  }

  if (!values.last_name) {
    return "Please provide a valid last name."
  }

  if (!values.new_email) {
    return "Please provide a valid email address."
  }

  if (!values.contact_number) {
    return "Please provide a valid contact number."
  }

  if (!values.company_name) {
    return "Please provide a valid company name."
  }

  if (!values.password) {
    return "Please provide a valid password."
  }

  if (!values.card_name) {
    return "Please provide a cardholder."
  }
}

const RegisterForm: any = ({ user, planName, planId, stripe }: any) => {
  const { register } = useCurrentUser()
  const formState = useFormState(userFormName)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [subscriberEmail, setSubscriberEmail] = useSubscriberEmail()
  const [initialValues, setInitialValues] = useState<any>()

  const submitting = formState && formState.getIn(["meta", "submitting"])
  const dirty = formState && formState.getIn(["meta", "dirty"])
  const isError = formState && !!Object.keys(formState.errors || {}).length

  useEffect(() => {
    let _initialValues = {}

    if (user) {
      _initialValues = {
        ...user,
        new_email: user.email,
        password: "*****************",
      }
    } else if (formState) {
      _initialValues = { ...formState.values }
    } else if (subscriberEmail) {
      _initialValues = { new_email: subscriberEmail }
    }

    setInitialValues({ ..._initialValues })
  }, [])

  async function onSubmit(values: any): Promise<void> {
    values = {
      ...values,
      email: values.new_email,
      user_name: values.email,
      plan: planName,
    }

    const errorMsg = validateInput(values)
    if (errorMsg) {
      throw Error(errorMsg)
    }

    setIsFormSubmitted(true)

    try {
      const { token } = await stripe.createToken({
        type: "card",
        email: values.email,
        name: values.card_name,
      })

      if (!token) {
        setIsFormSubmitted(false)
        throw Error("Payment is Invalid")
      }

      values.token = token.id
      values.last4 = token.card.last4
      values.plan = planId

      const res = await register({ variables: { input: values } })
      const newUser = res.data.user
      const organizationUuid = res.data.user.organizationUuid
      setSubscriberEmail(null)

      if (window !== undefined && window.analytics) {
        window.analytics.identify(newUser.id, {
          firstName: newUser.first_name,
          lastName: newUser.last_name,
          name: `${newUser.first_name} ${newUser.last_name}`,
          email: newUser.email,
          phone: newUser.contact_number,
          active: newUser.active,
          planName: planName,
        })
      }

      window.location.replace(
        `${config.GATSBY_ADMIN_DOMAIN}/${organizationUuid}/handbooks/create?firstload=true`
      )
    } catch (e) {
      console.log("error when submit", e)
      throw Error(getErrorMsgFromResponse(e))
    } finally {
      setIsFormSubmitted(false)
    }
  }

  return (
    <FormStyled
      name={userFormName}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <input type="email" name="email" style={{ opacity: 0 }} />
      <input
        type="password"
        name="fakepasswordremembered"
        style={{ opacity: 0 }}
      />

      <Row>
        <FormField
          name="first_name"
          title="First Name"
          placeholder="First Name"
          disabled={!!user}
          component={Input}
        />
        <FormField
          name="last_name"
          title="Last name"
          placeholder="Last Name"
          disabled={!!user}
          component={Input}
        />
      </Row>

      <FormField
        name="new_email"
        title="Your company email address"
        autoComplete="nope-user_name"
        disabled={!!user}
        component={EmailInputWithRealTimeValidation}
        placeholder="john@company.com"
      />

      <FormField
        name="contact_number"
        title="Phone number"
        placeholder="1 (646) 646-6464"
        autoComplete="nope-contact_number"
        disabled={!!user}
        component={PhoneInputRegister}
      />

      <FormField
        name="company_name"
        title="Company name"
        placeholder="Company Name"
        autoComplete="nope-company_name"
        disabled={!!user}
        component={Input}
      />

      <FormField
        name="password"
        title="Set a password"
        placeholder="A secure password"
        type="password"
        autoComplete="nope-password"
        disabled={!!user}
        component={Input}
      />

      <PaymentForm />
      <ErrorMessage>{formState && !dirty && formState.errors._}</ErrorMessage>

      <div className="flex my-7">
        <ButtonBlue
          disabled={(isFormSubmitted || submitting || isError) && dirty}
          type="submit"
          css={css`
            padding: 12px 16px;
          `}
        >
          {!isFormSubmitted ? (
            `Start my trial`
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 1rem",
              }}
            >
              <span className="mr-2">Loading...</span>
              <PaymentLoading />
            </div>
          )}
        </ButtonBlue>
        <img className="ml-4" src={paymentIcon} />
      </div>
    </FormStyled>
  )
}

const InjectedPayment = injectStripe(RegisterForm)

export default (props: any) => {
  const [stripe, setStripe] = useState<any>(null)

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(config.STRIPE_PUBLISHABLE_KEY))
    }
  }, [])

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <InjectedPayment {...props} />
      </Elements>
    </StripeProvider>
  )
}
